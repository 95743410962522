<template>
  <div class="employee">
    <EmployeeSelector @change="onSelect" :disabled="!phase.configurable">
      <a-button
        style="width: 200px; margin-right: 5px"
        :disabled="!phase.configurable"
      >
        <div class="left" v-if="staffItem.userName">
          <span v-if="staffItem.orgName">{{ staffItem.orgName }}-</span>
          <span>{{ staffItem.userName }}</span>
        </div>
        <div class="left" v-else>请选择人员</div>
      </a-button>
    </EmployeeSelector>

    <a-input-number
      :min="0.01"
      :max="100"
      v-model="staffItem.allocateRatio"
      style="margin-right: 4px"
      :disabled="!phase.configurable"
    />
    <a-radio-group
      button-style="solid"
      v-model="staffItem.allocateUnit"
      :disabled="!phase.configurable"
    >
      <a-radio-button value="%"> % </a-radio-button>
      <a-radio-button value="万"> 万 </a-radio-button>
    </a-radio-group>
    <a-input-number
      :value="showMoney"
      :disabled="true"
      style="margin-left: 10px; margin-right: 5px"
    />
    <span>万</span>

    <template v-if="phase.configurable">
      <a-button
        size="small"
        type="primary"
        shape="circle"
        icon="plus"
        style="margin-left: 10px"
        @click="add"
      ></a-button>
      <a-button
        size="small"
        type="danger"
        shape="circle"
        icon="minus"
        style="margin-left: 10px"
        @click="reduce"
        v-if="index !== 0"
      ></a-button>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import EmployeeSelector from "@/components/employee-selector";
export default {
  props: {
    phase: {
      type: Object,
      default: () => ({}),
    },

    majorGroup: {
      type: String,
      default: "",
    },
    phaseItem: {
      type: Object,
      default: () => ({}),
    },

    money: {
      type: Number,
      default: 0,
    },

    role: {
      type: String,
      default: "",
    },

    // 当前节点
    staffItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  components: {
    EmployeeSelector,
  },

  computed: {
    ...mapState("allocate2022", ["list"]),

    ...mapGetters("setting", ["findDataDict"]),

    // 页面展示的金额值
    showMoney() {
      if (this.staffItem.allocateUnit === "万") {
        return this.staffItem.allocateRatio;
      } else {
        if (
          typeof this.staffItem.allocateRatio === "number" &&
          !isNaN(this.staffItem.allocateRatio)
        ) {
          // 根据比例做计算
          return (
            (this.staffItem.allocateRatio * 0.01 * this.money) /
            10000
          ).toFixed(6);
        } else {
          return null;
        }
      }
    },
  },

  methods: {
    // 选择员工
    onSelect(arr) {
      if (arr.length === 0) {
        return;
      }

      const obj = arr[0];

      if (this.role) {
        const list = this.phaseItem.staffList.filter(
          (item) => item.role === this.role
        );
        const realObj = list[this.index];

        const realIndex = this.phaseItem.staffList.findIndex(
          (item) => item === realObj
        );

        console.log("realIndex", realIndex);

        this.phaseItem.staffList.splice(realIndex, 1, {
          ...this.staffItem,
          userId: obj.userId,
          userName: obj.name,
          orgId: obj.deptFullid,
          orgName: obj.deptUniqueName,
        });
      } else {
        this.phaseItem.staffList.splice(this.index, 1, {
          ...this.staffItem,
          userId: obj.userId,
          userName: obj.name,
          orgId: obj.deptFullid,
          orgName: obj.deptUniqueName,
        });
      }
    },

    add() {
      this.phaseItem.staffList.push({
        ...this.staffItem,
      });
    },
    reduce() {
      if (this.role) {
        const list = this.phaseItem.staffList.filter(
          (item) => item.role === this.role
        );
        const realObj = list[this.index];

        const realIndex = this.phaseItem.staffList.findIndex(
          (item) => item === realObj
        );

        this.phaseItem.staffList.splice(realIndex, 1);
      } else {
        this.phaseItem.staffList.splice(this.index, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 20px;
}
.item:last-child {
  margin-bottom: 0;
}

.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
}
.title {
  display: flex;
  align-items: center;
}
.title > span {
  width: 88px;
  height: 32px;
  font-size: 13px;
  background: #1890ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.employee {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.employee:last-child {
  margin-bottom: 0;
}
</style>